<template>
  <div>
    <frequencyDistributionForMicrobe :chartData="chartData" title="유익균"></frequencyDistributionForMicrobe>
  </div>
</template>

<script>
import frequencyDistributionForMicrobe from './frequencyDistributionForMicrobe'

export default {
  name: 'BeneficialBacteria', //유익균
  components: { frequencyDistributionForMicrobe },
  data() {
    return {
      chartData : {
        Akkermansia : {},
        Faecalibacterium : {},
        Roseburia : {},
        Parabacteroides : {},
        Mitsuokella : {},
        Odoribacter : {},
        Fusicatenibacter : {},
        Vampirovibrio : {}
      },
    }

  },
  methods: {
  },
  mounted() {
  },
  created () {
  },
  computed : {
  }

}
</script>

<style scoped>
.vCard {
 border: 1px solid white;
}
.v-data-table::v-deep th {
  font-size: 14px !important;
}
.v-text-field >>> label {
  font-size: 13px;
}
</style>

